@import "@/assets/scss/var.scss";
.pay {
    background: #f7f8fa;
    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
        border-color: $border-primary;
    }
    .section {
        padding: 20px 30px;
        margin-bottom: 10px;
        margin-top: 10px;
        background: #fff;
        .status {
            padding: 30px 36px;
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: flex-start;
            .center {
                flex: 1;
            }
            .icon {
                width: 8em;
                height: 8em;
                margin: 36px;
            }
            .status-msg {
                font-size: 1.55em;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .status-msg ~ * {
                margin-bottom: 10px;
                .date {
                    color: $color-primary;
                }
            }
            .order-detail {
                list-style: none;
                margin: 0;
                padding: 0;
                display: table;
                width: 100%;
                font-size: 14px;
                color: #999;
                .hv-label {
                    width: 140px;
                }
                .hv-val {
                    font-weight: bold;
                    font-size: 24px;
                    color: $color-primary;
                }
                > li {
                    margin-bottom: 5px;
                }
                li > div {
                    display: table-cell;
                }
            }
            > :last-child {
                align-self: flex-end;
                margin-left: auto;
            }
        }
        .title {
            margin-top: 20px;
        }
        .option-btn {
            margin: 15px 0 15px 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #f1f1f1;
            .el-radio {
                position: relative;
                // min-width: 245px;
                // width: 21%;
                height: 50px;
                line-height: 50px;
            }
            .el-radio.is-bordered .el-radio__inner {
                opacity: 0;
                outline: none;
                position: absolute;
                margin: 0;
                z-index: -1;
                height: 0px;
                width: 0px;
            }
            .el-radio-group {
                display: flex;
                flex-wrap: wrap;
            }
            .el-radio__label {
                padding-left: 0px;
            }
            .el-radio__label .el-image {
                margin-right: 5px;
            }
            .el-radio.is-bordered {
                padding-top: 0px;
            }
            .el-radio__label {
                display: inline-flex;
                align-items: center;
                height: 100%;
            }
            .el-radio.is-bordered.is-checked {
                border-color: $border-primary;
            }
            .el-radio__input.is-checked + .el-radio__label {
                color: $color-primary;
            }
            .el-radio__input.is-checked + .el-radio__label::before {
                content: "";
                display: block;
                width: 8px;
                height: 4px;
                border-left: 1px solid #fff;
                border-bottom: 1px solid #fff;
                transform: rotate(-45deg);
                position: absolute;
                right: 2px;
                bottom: 5px;
                z-index: 1;
            }
            .el-radio__input.is-checked + .el-radio__label::after {
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-bottom: 20px solid $border-primary;
                border-left: 25px solid transparent;
                position: absolute;
                right: 0;
                bottom: 0;
            }
            .el-radio.is-bordered.is-disabled {
                cursor: not-allowed;
                border: 2px dashed;
                border-color: #ebeef5;
                /* border-style: dashed; */
            }
            .payW {
                display: flex;
                align-items: center;
                img.el-image__inner {
                    display: flex;
                    max-width: 50px;
                    max-height: 50px;
                }
            }
        }
        .wxpay-code {
            display: flex;
            flex-flow: column;
            align-items: center;
            .qrCodeUrl {
                margin-top: 10px;
                margin-bottom: 10px;
            }
            > div {
                color: #999;
            }
        }
        .bottom {
            text-align: center;
            margin-top: 30px;
        }
        ul, li {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }
}
